import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Checkbox } from '@positivote/design-system/components/Checkbox'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Typography } from '@positivote/design-system/components/Typography'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { GenderDictionaryValue, genderDictionary } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useListProfileRoles } from '@/modules/hub/profiles/hooks'
import { UserStepperState } from '@/modules/hub/users/contracts/forms'
import { useCreateUser, useUpdateUser } from '@/modules/hub/users/hooks'
import { formServiceSanitizer } from '@/modules/hub/users/sanitizers'

interface UserPreviewStepProps {
  stepperState: UserStepperState
}

export const UserPreviewStep = forwardRef(function UserPreviewStep(
  { stepperState }: UserPreviewStepProps,
  ref
) {
  const listProfileRoles = useListProfileRoles({})
  const createUser = useCreateUser()
  const updateUser = useUpdateUser()
  const params = useParams()
  const navigate = useNavigate()
  const { profile } = useAuth()
  const genderFound = genderDictionary[stepperState.userData.form?.gender ?? ''] as
    | GenderDictionaryValue
    | undefined

  const onSubmit = useCallback(
    (setIsLoading: (isLoading: boolean) => void): void => {
      const model = formServiceSanitizer(stepperState.userData)

      setIsLoading(true)
      if (params.id) {
        updateUser.mutate({
          model: {
            ...model,
            institutionId: profile!.organizationId,
            userId: Number(params.id)
          },
          page: 1,
          onSuccess: () => {
            navigate(-1)
          },
          onError: () => {
            navigate(-1)
          }
        })
      } else {
        createUser.mutate({
          model: {
            ...model,
            institutionId: profile!.organizationId
          },
          onSuccess: () => {
            navigate(-1)
          },
          onError: () => {
            navigate(-1)
          }
        })
      }
    },
    [createUser, navigate, params.id, profile, stepperState.userData, updateUser]
  )

  useImperativeHandle(ref, () => ({ onSubmit }), [onSubmit])
  return (
    <Grid>
      <Div
        css={{
          display: 'flex',
          borderWidth: '$thin',
          borderStyle: 'solid',
          borderRadius: '$xl',
          borderColor: '$outline-variant',
          padding: '$lg',
          gap: '$md'
        }}
      >
        <Grid
          xl={1}
          lg={2}
          md={2}
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '$sm'
          }}
        >
          <Image
            FallbackImage={() => (
              <Div
                data-testid="UsersList-FallbackImage"
                css={{
                  backgroundColor: '$primary-container',
                  borderRadius: '$full',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  height: 120,
                  maxWidth: 120,
                  width: '100%'
                }}
              >
                <PersonIcon fill="$on-primary-container" css={{ minHeight: 80, minWidth: 80 }} />
              </Div>
            )}
            css={{ borderRadius: '$full', objectFit: 'cover', height: 120, width: 120 }}
            src={stepperState.userData.form?.picture}
            alt="picture"
          />
        </Grid>
        <Grid xl={11} lg={10} css={{ display: 'flex', flexDirection: 'column', gap: '$md' }}>
          <Typography variant="titleLarge">
            {`${stepperState.userData.form?.name} ${stepperState.userData.form?.lastName}`}
          </Typography>
          <Divider />
          <Grid spacing="$md">
            <Grid xl={6} css={{ display: 'flex', gap: '$sm' }}>
              <Typography variant="titleMedium">
                {i18n().modules.hub.users.pages.form.stepper.preview.login}
              </Typography>
              <Typography variant="bodyLarge">{stepperState.userData.form?.login}</Typography>
            </Grid>
            {stepperState.userData.form?.email && (
              <Grid xl={6} css={{ display: 'flex', gap: '$sm' }}>
                <Typography variant="titleMedium">
                  {i18n().modules.hub.users.pages.form.stepper.preview.email}
                </Typography>
                <Typography variant="bodyLarge">{stepperState.userData.form.email}</Typography>
              </Grid>
            )}
            <Grid xl={6} css={{ display: 'flex', gap: '$sm' }}>
              <Typography variant="titleMedium">
                {i18n().modules.hub.users.pages.form.stepper.preview.code}
              </Typography>
              <Typography variant="bodyLarge">{stepperState.userData.form?.code}</Typography>
            </Grid>
            {stepperState.userData.form?.document && (
              <Grid xl={6} css={{ display: 'flex', gap: '$sm' }}>
                <Typography variant="titleMedium">
                  {i18n().modules.hub.users.pages.form.stepper.preview.cpf}
                </Typography>
                <Typography variant="bodyLarge">{stepperState.userData.form.document}</Typography>
              </Grid>
            )}
            <Grid xl={6} css={{ display: 'flex', gap: '$sm' }}>
              <Typography variant="titleMedium">
                {i18n().modules.hub.users.pages.form.stepper.preview.birthday}
              </Typography>
              <Typography variant="bodyLarge">{stepperState.userData.form?.birthday}</Typography>
            </Grid>
            {stepperState.userData.form?.gender && (
              <Grid xl={6} css={{ display: 'flex', gap: '$sm' }}>
                <Typography variant="titleMedium">
                  {i18n().modules.hub.users.pages.form.stepper.preview.gender}
                </Typography>
                <Typography variant="bodyLarge">{genderFound?.value}</Typography>
              </Grid>
            )}
            {stepperState.userData.form?.phone && (
              <Grid xl={6} css={{ display: 'flex', gap: '$sm' }}>
                <Typography variant="titleMedium">
                  {i18n().modules.hub.users.pages.form.stepper.preview.phone}
                </Typography>
                <Typography variant="bodyLarge">{stepperState.userData.form.phone}</Typography>
              </Grid>
            )}
          </Grid>
          <Divider />
          <Typography variant="titleMedium">
            {i18n().modules.hub.users.pages.form.stepper.preview.userProfiles}
          </Typography>
          <Div css={{ display: 'flex', gap: '$md' }}>
            {listProfileRoles.data?.registers
              .filter((profiles) => stepperState.userData.form?.roles.includes(profiles.id))
              .map((profiles) => <Chip color="neutral" label={profiles.name} key={profiles.id} />)}
          </Div>

          {stepperState.userData.form?.allowedImageUseOnEdtech && (
            <>
              <Divider />
              <Checkbox
                inputProps={{
                  checked: stepperState.userData.form.allowedImageUseOnEdtech
                }}
                disabled
                label={i18n().modules.hub.users.pages.form.stepper.preview.allowedImageUseOnEdtech}
              />
            </>
          )}
          {!!stepperState.userData.students?.length && (
            <Grid
              spacing="$md"
              css={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center'
              }}
            >
              {stepperState.userData.students.map((students, index) => (
                <Grid xl={6} key={students.id}>
                  <BaseCard
                    css={{
                      borderRadius: '$lg',
                      flex: 1,
                      backgroundColor: '$surface-2',
                      '& .BaseCard-StateLayer': {
                        padding: '$md $lg',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '$md',
                        justifyContent: 'space-between'
                      }
                    }}
                  >
                    <Div css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                      <Image
                        alt={`${students.name} picture`}
                        src={students.picture}
                        FallbackImage={() => (
                          <IconWrapper
                            data-testid="UsersList-FallbackImage"
                            size="$2xl"
                            css={{ backgroundColor: '$primary-container' }}
                          >
                            <PersonIcon fill="$on-primary-container" />
                          </IconWrapper>
                        )}
                        css={{
                          height: '$2xl',
                          width: '$2xl',
                          borderRadius: '$full',
                          objectFit: 'cover'
                        }}
                      />
                      <Typography
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                        data-testid={`Typography-listItemUsername-${index}`}
                      >
                        {students.name}
                      </Typography>
                    </Div>
                  </BaseCard>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Div>
    </Grid>
  )
})
