import { Badges } from '@positivote/design-system/components/Badges'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Typography } from '@positivote/design-system/components/Typography'
import { AlertIcon } from '@positivote/design-system/icons/Alert'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { ErrorIcon } from '@positivote/design-system/icons/Error'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { useNavigate } from 'react-router-dom'

import { i18n } from '@/common/i18n'
import { ManageSearchIcon } from '@/fixme/icons/ManageSearch'
import { UploadFileIcon } from '@/fixme/icons/uploadFile'
import { statusAcademicSync } from '@/modules/hub/academic-sync/sanitizers'

export interface FirstCardProps {
  data?: {
    id?: string
    fileName?: string
    processed?: string
    time?: string
    submittedBy?: string
    statusFile?: string
    totalUsers?: number
    totalLinks?: number
    schoolYearTitle?: string
  }
}

export function FirstCard({ data }: FirstCardProps): JSX.Element {
  const navigate = useNavigate()
  return (
    <BaseCard
      css={{
        padding: '$md',
        borderRadius: '$md',
        '& .BaseCard-StateLayer': { gap: '$md' }
      }}
    >
      <Div
        css={{
          display: 'flex',
          gap: '$md',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Div css={{ display: 'flex', gap: '$md' }}>
          <Typography variant="titleMedium">{data?.fileName}</Typography>
          <Chip label={data?.schoolYearTitle ?? ''} />
        </Div>

        {data?.id && (
          <LinkButton
            onClick={() => navigate(`/data-management/my-imports/${data.id}`)}
            LeadingIcon={<ManageSearchIcon />}
          >
            <Typography variant="labelMedium">
              {i18n().modules.hub.academicSync.pages.list.viewData}
            </Typography>
          </LinkButton>
        )}
      </Div>
      <Divider />
      <Div css={{ display: 'flex', gap: '$lg' }}>
        <UploadFileIcon fill="$primary" size={64} />
        <Div css={{ display: 'flex', flexDirection: 'column', gap: '$sm', flex: 1 }}>
          <Div css={{ display: 'flex', gap: '$sm' }}>
            <Typography variant="titleMedium">
              {i18n().modules.hub.academicSync.pages.list.processedIn}
            </Typography>
            <Div css={{ display: 'flex', gap: '$sm' }}>
              <Typography variant="bodyLarge">{data?.processed}</Typography>
              <Div css={{ display: 'flex', alignItems: 'center' }}>
                <Badges fill="$on-surface-variant" />
              </Div>
              <Typography variant="bodyLarge">{data?.time}</Typography>
            </Div>
          </Div>
          <Div css={{ display: 'flex', gap: '$sm' }}>
            <Typography variant="titleMedium">
              {i18n().modules.hub.academicSync.pages.list.sender}
            </Typography>
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$sm' }}>
              <IconWrapper size="$lg" css={{ backgroundColor: '$primary-container' }}>
                <PersonIcon size={18} />
              </IconWrapper>
              <Typography variant="bodyLarge">{data?.submittedBy}</Typography>
            </Div>
          </Div>
        </Div>
        <Div css={{ display: 'flex', flexDirection: 'column', gap: '$sm', flex: 1 }}>
          <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
            <Typography variant="titleMedium">
              {i18n().modules.hub.academicSync.pages.list.fileStatus}
            </Typography>
            {data?.statusFile === statusAcademicSync.PROCESS_SUCCESS && (
              <CheckCircleIcon fill="$success" />
            )}
            {data?.statusFile === statusAcademicSync.PROCESS_ERROR && (
              <ErrorIcon fill="$critical" />
            )}
            {data?.statusFile === statusAcademicSync.PROCESSED_WITH_ERROS && (
              <AlertIcon fill="$warning" />
            )}
            <Typography variant="bodyLarge">{data?.statusFile}</Typography>
          </Div>
          <Div css={{ display: 'flex', gap: '$sm' }}>
            <Typography variant="titleMedium">
              {i18n().modules.hub.academicSync.pages.list.totalUser}
            </Typography>
            <Typography variant="bodyLarge">
              {data?.totalUsers} {i18n().modules.hub.academicSync.pages.list.users}
            </Typography>
          </Div>
          <Div css={{ display: 'flex', gap: '$sm' }}>
            <Typography variant="titleMedium">
              {i18n().modules.hub.academicSync.pages.list.totalLinks}
            </Typography>
            <Typography variant="bodyLarge">
              {data?.totalLinks} {i18n().modules.hub.academicSync.pages.list.associations}
            </Typography>
          </Div>
        </Div>
      </Div>
    </BaseCard>
  )
}
