import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { ErrorIcon } from '@positivote/design-system/icons/Error'
import { v4 as uuidV4 } from 'uuid'

import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { i18n } from '@/common/i18n'
import {
  DefaultAcademicSyncData,
  DisciplineAcademicSync
} from '@/modules/hub/academic-sync/contracts'

export interface ListDetailsDisciplineProps {
  data?: DefaultAcademicSyncData<DisciplineAcademicSync>
  filterStatus: string
}

export function ListDetailsDiscipline({
  data,
  filterStatus
}: ListDetailsDisciplineProps): JSX.Element {
  const newDataFilter =
    filterStatus === 'all'
      ? data?.data
      : data?.data.filter((status) => status.status === filterStatus)
  return (
    <Div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {!data?.data.length ? (
        <EmptyList title={i18n().modules.hub.academicSync.components.emptyListDiscipline} />
      ) : !newDataFilter?.length ? (
        <EmptySearch />
      ) : (
        <Div
          css={{
            display: 'flex',
            flex: 1,
            backgroundColor: '$surface-2',
            borderRadius: '$md',
            overflow: 'hidden',
            flexDirection: 'column'
          }}
        >
          <Grid
            css={{
              display: 'flex',
              padding: '$2xs $md',
              background: '$outline-variant'
            }}
          >
            <Grid xl={1} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
              <Typography
                data-testid="Typography-header-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.status}
              </Typography>
            </Grid>

            <Grid xl={1}>
              <Typography
                data-testid="Typography-header-code"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.line}
              </Typography>
            </Grid>
            <Grid xl={3}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.codeDiscipline}
              </Typography>
            </Grid>
            <Grid xl={3}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.nameDiscipline}
              </Typography>
            </Grid>
            <Grid xl={3}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.baseDiscipline}
              </Typography>
            </Grid>
          </Grid>

          {newDataFilter.map((student) => (
            <Grid
              key={uuidV4()}
              css={{
                display: 'flex',
                padding: '$2xs $md',
                borderWidth: '$thin',
                borderStyle: 'solid',
                borderColor: '$surface-variant',
                alignItems: 'center'
              }}
            >
              <Grid xl={1} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                {student.status === 'SUCCESS' && (
                  <Tooltip label={student.message} placement="top">
                    <CheckCircleIcon fill="$success" />
                  </Tooltip>
                )}
                {student.status === 'ERROR' && (
                  <Tooltip label={student.message} placement="top">
                    <ErrorIcon css={{ pointerEvents: 'unset' }} fill="$critical" />
                  </Tooltip>
                )}
              </Grid>

              <Grid xl={1}>
                <Typography
                  data-testid="Typography-header-code"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.systemId}
                </Typography>
              </Grid>
              <Grid xl={3}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.code}
                </Typography>
              </Grid>
              <Grid xl={3}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.name}
                </Typography>
              </Grid>
              <Grid xl={3}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.disciplineBase}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Div>
      )}
    </Div>
  )
}
