import * as yup from 'yup'

import {
  formatPtBrDateToIsoString,
  isDateSmallerToNow,
  isValidDate,
  ptBrDateRegex,
  validateCpf
} from '@/common/helpers'
import { i18n } from '@/common/i18n'

export const userDataValidationSchema = yup.object().shape({
  roles: yup
    .array()
    .of(yup.number().required())
    .min(1, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minRoles)
    .required(),
  name: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredName)
    .min(2, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minName),
  lastName: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredLastName)
    .min(2, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minLastName),
  allowedImageUseOnEdtech: yup.boolean().required(),
  picture: yup.string().nullable(),
  code: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredCode)
    .min(6, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minCode),
  login: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredLogin)
    .min(6, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minLogin),
  birthday: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredBirthday)
    .test({
      name: 'isDateSmallerToNow',
      message: i18n().modules.hub.users.pages.form.stepper.registerData.validators.invalidBirthday,
      test: (value) => isDateSmallerToNow(formatPtBrDateToIsoString(value))
    })
    .test({
      name: 'isValidDate',
      message: i18n().modules.hub.users.pages.form.stepper.registerData.validators.invalidBirthday,
      test: (value) => isValidDate(value, ptBrDateRegex).isValid
    }),
  email: yup
    .string()
    .email(i18n().common.validators.emailInvalid)
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredEmail),
  gender: yup.string().nullable(),
  document: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredDocument)
    .test({
      name: 'validateCpf',
      message: i18n().common.validators.cpfInvalid,
      test: (value) => validateCpf(value)
    })
    .nullable(),
  phone: yup.string().nullable(),
  students: yup.array().optional().of(yup.number().required())
})

export const userStudentDataValidationSchema = yup.object().shape({
  roles: yup
    .array()
    .of(yup.number().required())
    .min(1, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minRoles)
    .required(),
  name: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredName)
    .min(2, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minName),
  lastName: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredLastName)
    .min(2, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minLastName),
  allowedImageUseOnEdtech: yup.boolean().required(),
  picture: yup.string().nullable(),
  code: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredCode)
    .min(6, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minCode),
  login: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredLogin)
    .min(6, i18n().modules.hub.users.pages.form.stepper.registerData.validators.minLogin),
  birthday: yup
    .string()
    .required(i18n().modules.hub.users.pages.form.stepper.registerData.validators.requiredBirthday)
    .test({
      name: 'isDateSmallerToNow',
      message: i18n().modules.hub.users.pages.form.stepper.registerData.validators.invalidBirthday,
      test: (value) => isDateSmallerToNow(formatPtBrDateToIsoString(value))
    })
    .test({
      name: 'isValidDate',
      message: i18n().modules.hub.users.pages.form.stepper.registerData.validators.invalidBirthday,
      test: (value) => isValidDate(value, ptBrDateRegex).isValid
    }),
  email: yup.string().email(i18n().common.validators.emailInvalid).nullable(),
  gender: yup.string().nullable(),
  document: yup
    .string()
    .test({
      name: 'validateCpf',
      message: i18n().common.validators.cpfInvalid,
      test: (value) => validateCpf(value)
    })
    .nullable(),
  phone: yup.string().nullable(),
  students: yup.array().optional().of(yup.number().required())
})
