import { useAlert } from '@positivote/design-system/hooks'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  ImportAcademicSyncHookParams,
  ListAcademicSyncHookParams,
  ListAcademicSyncHookResult,
  ShowAcademicSyncHookParams,
  ShowAcademicSyncHookResult
} from './contracts/hooks'
import {
  importAcademicSyncService,
  listAcademicSyncService,
  showAcademicSyncService
} from './services'

const hookKey = 'academic-sync'

export function useListAcademicSync({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAcademicSyncHookParams): UseQueryResult<ListAcademicSyncHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listAcademicSync = await listAcademicSyncService(model)
        onSuccess?.(listAcademicSync)
        return listAcademicSync
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useShowDiscipline({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowAcademicSyncHookParams): UseQueryResult<ShowAcademicSyncHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'show', model],
    queryFn: async () => {
      try {
        const showAcademicSync = await showAcademicSyncService(model)
        onSuccess?.(showAcademicSync)
        return showAcademicSync
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useImportAcademicSync(): UseMutationResult<
  null,
  ApplicationException,
  ImportAcademicSyncHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'import'],
    mutationFn: async ({ model, onSuccess, onError }: ImportAcademicSyncHookParams) => {
      try {
        const createDiscipline = await importAcademicSyncService(model)
        onSuccess?.(createDiscipline)
        void queryClient.invalidateQueries()
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.academicSync.pages.list.alertLabel
        })

        return null
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
