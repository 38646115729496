import { Main } from '@positivote/design-system/components/Main'
import { StepProps, Stepper } from '@positivote/design-system/components/Stepper'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { ExitConfirmDialog } from '@/common/components/ExitConfirmDialog'
import { cellMask, changePageTitle, cpfMask } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { UserDataStepForm, UserStepperState } from '@/modules/hub/users/contracts/forms'
import { useShowUser } from '@/modules/hub/users/hooks'

import { UserPreviewStep } from './Preview'
import { UserDataStep } from './RegisterData'

export function FormUser(): JSX.Element {
  const [currentStepPosition, setCurrentStepPosition] = useState(1)
  const [isDirty, setIsDirty] = useState(false)
  const [nextLocation, setNextLocation] = useState(0)
  const params = useParams()
  const isEditing = !!params.id
  changePageTitle(i18n().modules.hub.users.pages.form.pageTitle(isEditing))
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [stepperState, setStepperState] = useState<UserStepperState>({
    userData: {
      form: null,
      students: null,
      hasError: false,
      isValid: false,
      canGoNext: true
    },
    preview: {
      picture: null,
      canGoNext: true
    }
  })

  const stepperRef = useRef<{
    validateDataForm: () => Promise<UserDataStepForm | null>
    onSubmit: (setIsLoading: (isLoading: boolean) => void) => void
  }>(null)

  async function onClickNext(newStep: StepProps): Promise<void> {
    const disciplineDataStepFormData = await stepperRef.current?.validateDataForm()
    if (!disciplineDataStepFormData) {
      return
    }
    setStepperState((oldState) => ({
      ...oldState,
      userData: {
        ...oldState.userData,
        form: {
          ...disciplineDataStepFormData
        }
      }
    }))
    setCurrentStepPosition(newStep.position)
  }

  const showUser = useShowUser({
    model: {
      userId: Number(params.id!)
    },
    queryOptions: {
      enabled: !!params.id
    },
    onError: () => {
      navigate('/data-management/my-users')
    }
  })

  useEffect(() => {
    if (showUser.data) {
      setStepperState({
        userData: {
          form: {
            allowedImageUseOnEdtech: !!showUser.data.allowedImageUseOnEdtech,
            code: showUser.data.code,
            birthday: showUser.data.birthday,
            email: showUser.data.email ?? null,
            lastName: showUser.data.lastName,
            name: showUser.data.firstName,
            login: showUser.data.login,
            roles: showUser.data.roles.map((student) => student.idRole),
            gender: showUser.data.gender ?? null,
            picture: showUser.data.picture ?? null,
            phone: cellMask(showUser.data.phone ?? ''),
            document: cpfMask(showUser.data.document),
            students: showUser.data.students.map((student) => student.id)
          },
          canGoNext: true,
          hasError: false,
          isValid: true,
          students: showUser.data.students
        },
        preview: {
          canGoNext: true,
          picture: showUser.data.picture
        }
      })
    }
  }, [showUser.data])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <ExitConfirmDialog initialIsDirty={isDirty} location={nextLocation} />
      <AppBar
        title={i18n().modules.hub.dataManagement.pages.menu.appBar.title}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.users.pages.form.appBar.breadcrumbs.overview,
            onClick: () => {
              setNextLocation(-2)
              navigate(-2)
            }
          },
          {
            label: i18n().modules.hub.users.pages.form.appBar.breadcrumbs.myUsers,
            onClick: () => {
              setNextLocation(-1)
              navigate(-1)
            }
          },
          {
            label:
              i18n().modules.hub.users.pages.form.appBar.breadcrumbs[
                isEditing ? 'editUser' : 'newUsers'
              ]
          }
        ]}
      />
      <Stepper
        orientation="horizontal"
        position="start"
        stepOrientation="horizontal"
        nextButtonProps={{ onClick: onClickNext }}
        previousButtonProps={{
          onClick: (newStep) => setCurrentStepPosition(newStep.position)
        }}
        cancelButtonProps={{
          onClick: () => navigate(-1)
        }}
        isLoading={isLoading}
        doneButtonProps={{
          label: i18n().modules.hub.users.pages.form.stepper.preview.saveUser,
          onClick: () => {
            setIsDirty(false)
            stepperRef.current?.onSubmit(setIsLoading)
          }
        }}
        mobileProps={{ breakpoint: Breakpoint.sm, progressType: 'dots' }}
        currentStepPosition={currentStepPosition}
        steps={[
          {
            position: 1,
            title: i18n().modules.hub.users.pages.form.stepper.registerData.stepTitle,
            status: stepperState.userData.hasError ? 'error' : 'active',
            canGoNext: stepperState.userData.canGoNext,
            showPreviousButton: true,
            children: (
              <UserDataStep
                onChangeIsDirty={(props) => setIsDirty(props)}
                isLoading={showUser.isLoading}
                setStepState={(stepState) =>
                  setStepperState((oldState) => ({
                    ...oldState,
                    userData: { ...oldState.userData, ...stepState }
                  }))
                }
                stepState={stepperState.userData}
                ref={stepperRef}
              />
            )
          },
          {
            position: 2,
            title: i18n().modules.hub.users.pages.form.stepper.preview.stepTitle,
            status: 'active',
            canGoNext: true,
            showPreviousButton: true,
            children: <UserPreviewStep ref={stepperRef} stepperState={stepperState} />
          }
        ]}
        css={{
          flex: 1,
          padding: '$lg',
          '& .StepperHeader-Container': { width: 500, padding: '$none' },
          '& .Stepper-ChildrenWrapper': {
            display: 'flex',
            flex: 1
          }
        }}
      />
    </Main>
  )
}
