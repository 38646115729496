import { BaseCardButton } from '@positivote/design-system/components/BaseCardButton'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { Image } from '@positivote/design-system/components/Image'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { ProgressBar } from '@positivote/design-system/components/ProgressBar'
import { Typography } from '@positivote/design-system/components/Typography'
import { ANIMATION_DELAY } from '@positivote/design-system/constants'
import { useTheme } from '@positivote/design-system/hooks'
import { GoogleIcon } from '@positivote/design-system/icons/Google'
import { MicrosoftIcon } from '@positivote/design-system/icons/Microsoft'
import { OpenInNewIcon } from '@positivote/design-system/icons/OpenInNew'
import { QrCodeIcon } from '@positivote/design-system/icons/QrCode'
import { QuickAccessIcon } from '@positivote/design-system/icons/QuickAccess'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { HubLogo } from '@/common/assets/icons/HubLogo'
import { changePageTitle, debounceEvent, getAliasFromUrl } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { INPUT_SCROLL_DELAY_IN_MS } from '@/modules/hub/auth/constants'
import { useAuth } from '@/modules/hub/auth/contexts'

import { PasswordFormLogin } from './Password'

export function Login(): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.login.pageTitle)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [getPercentage, setPercentage] = useState(0)

  const {
    isLoading,
    isLoadingMicrosoft,
    isLoadingGoogle,
    isLoadingSsoApplication,
    isLoadingWhiteLabel,
    createSessionGoogle,
    createSessionMicrosoft,
    ssoApplication,
    whiteLabel,
    saveWhiteLabel,
    prepareOAuth2AndSso
  } = useAuth()
  const navigate = useNavigate()
  const { breakpoint } = useTheme()

  const isLoadingSsoOrWhiteLabel = isLoadingSsoApplication || isLoadingWhiteLabel
  const accessModes = [
    {
      key: 'google',
      isLoading: isLoadingGoogle,
      disabled: (isLoading || isLoadingMicrosoft) && !isLoadingGoogle,
      onClick: () => createSessionGoogle({}),
      icon: <GoogleIcon />,
      label: i18n().modules.hub.auth.pages.login.footerCards.google
    },
    {
      key: 'microsoft',
      isLoading: isLoadingMicrosoft,
      disabled: (isLoading || isLoadingGoogle) && !isLoadingMicrosoft,
      onClick: () => createSessionMicrosoft({}),
      icon: <MicrosoftIcon />,
      label: i18n().modules.hub.auth.pages.login.footerCards.microsoft
    },
    {
      key: 'qrCode',
      isLoading: false,
      disabled: isLoading || isLoadingMicrosoft || isLoadingGoogle,
      onClick: () => navigate('/qr-code'),
      icon: <QrCodeIcon removePadding size="$lg" fill="$on-surface" />,
      label: i18n().modules.hub.auth.pages.login.footerCards.qrCode
    },
    {
      key: 'quickAccess',
      isLoading: false,
      disabled: isLoading || isLoadingMicrosoft || isLoadingGoogle,
      onClick: () => navigate('/quick-access'),
      icon: <QuickAccessIcon size="$lg" fill="$amber-50" />,
      label: i18n().modules.hub.auth.pages.login.footerCards.quickAccess
    }
  ]

  function scrollIntoView(): void {
    debounceEvent(() => wrapperRef.current?.scrollIntoView(), INPUT_SCROLL_DELAY_IN_MS)()
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const percentageCoefficient = 4
      setPercentage((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + percentageCoefficient
      )
    }, ANIMATION_DELAY)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    prepareOAuth2AndSso()
    // DOCS: this useEffect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!getAliasFromUrl()) {
      saveWhiteLabel(undefined)
    }
  }, [whiteLabel, location.pathname])

  return (
    <BaseBackground
      shouldShowHeader={!isLoadingSsoOrWhiteLabel}
      shouldShowFooter={!isLoadingSsoOrWhiteLabel}
    >
      {isLoadingSsoOrWhiteLabel ? (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <HubLogo css={{ width: 310, height: 96, marginBottom: '$lg' }} />
          <Div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ProgressBar
              filledPercentage={getPercentage}
              css={{ width: 165, marginRight: '$sm' }}
            />
            <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
              {getPercentage}%
            </Typography>
          </Div>
        </Div>
      ) : (
        <Grid
          ref={wrapperRef}
          xl={5}
          lg={6}
          md={7}
          sm={9}
          xs={12}
          css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {ssoApplication ? (
            <>
              <Image
                alt={ssoApplication.name}
                src={ssoApplication.iconUrl}
                css={{
                  width: 96,
                  height: 96,
                  borderRadius: '$full',
                  borderStyle: 'solid',
                  borderColor: '$outline-variant',
                  borderWidth: '$thin',
                  objectFit: 'cover',
                  marginBottom: '$lg',
                  '@sm': { marginBottom: '$md' }
                }}
              />
              <Div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '$lg',
                  '@sm': { marginBottom: '$md' }
                }}
              >
                <Typography
                  variant="titleMedium"
                  css={{ color: '$on-surface-variant', textAlign: 'center' }}
                >
                  {i18n().modules.hub.auth.pages.login.titleSso}
                  <Typography
                    variant="titleMedium"
                    component="strong"
                    css={{ color: '$on-surface', marginLeft: '$3xs' }}
                  >
                    {ssoApplication.name}
                  </Typography>
                </Typography>
                {whiteLabel?.org.name ? (
                  <Typography
                    variant="titleMedium"
                    css={{ color: '$on-surface-variant', textAlign: 'center', marginTop: '$2xs' }}
                  >
                    {i18n().modules.hub.auth.pages.login.subTitleSso}
                    <Typography
                      variant="titleMedium"
                      component="strong"
                      css={{ color: '$on-surface', marginLeft: '$3xs' }}
                    >
                      {whiteLabel.org.name}
                    </Typography>
                  </Typography>
                ) : (
                  !!ssoApplication.siteUrl && (
                    <LinkButton
                      LeadingIcon={<OpenInNewIcon size={18} />}
                      onClick={() => window.open(ssoApplication.siteUrl ?? '', '_blank')}
                      css={{ marginTop: '$2xs' }}
                    >
                      {i18n().modules.hub.auth.pages.login.discoverTheApplication(
                        ssoApplication.name
                      )}
                    </LinkButton>
                  )
                )}
              </Div>
            </>
          ) : (
            <Typography
              variant={
                breakpoint === Breakpoint.sm
                  ? 'headlineSmall'
                  : breakpoint === Breakpoint.xs
                    ? 'titleLarge'
                    : 'headlineMedium'
              }
              css={{
                color: '$on-surface',
                textAlign: 'center',
                marginBottom: '$lg',
                '@sm': { marginBottom: '$md' }
              }}
            >
              {i18n().modules.hub.auth.pages.login.title}
            </Typography>
          )}

          <PasswordFormLogin scrollIntoView={scrollIntoView} />

          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginTop: '$lg',
              marginBottom: '$md',
              '@sm': { marginTop: '$md', marginBottom: '$2xs' }
            }}
          >
            <Divider />
            <Typography
              variant="bodyMedium"
              css={{
                color: '$on-surface-variant',
                marginLeft: '$lg',
                marginRight: '$lg',
                '@sm': { marginLeft: '$2xs', marginRight: '$2xs' }
              }}
            >
              {i18n().modules.hub.auth.pages.login.dividerOrLabel}
            </Typography>
            <Divider />
          </Div>
          <Grid
            xl={12}
            spacing={breakpoint <= Breakpoint.sm ? '$2xs' : '$md'}
            css={{ display: 'flex', justifyContent: 'center' }}
          >
            {accessModes.map((accessMode) => (
              <Grid
                key={accessMode.key}
                xl={3}
                xs={12}
                css={{ display: 'flex', flexDirection: 'column' }}
              >
                <BaseCardButton
                  data-testid={`BaseCardButton-${accessMode.key}`}
                  onClick={accessMode.onClick}
                  disabled={accessMode.disabled}
                  isLoading={accessMode.isLoading}
                  css={{
                    '& .BaseCardButton-StateLayer': {
                      flexDirection: 'column',
                      padding: '$2xs',
                      height: 88,
                      '@xs': { flexDirection: 'row', height: 40 }
                    }
                  }}
                >
                  {accessMode.icon}
                  <Typography
                    variant="labelSmall"
                    css={{
                      color: '$on-surface-variant',
                      marginTop: '$4xs',
                      '@xs': { marginTop: '$none', marginLeft: '$2xs' }
                    }}
                  >
                    {accessMode.label}
                  </Typography>
                </BaseCardButton>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </BaseBackground>
  )
}
