import * as yup from 'yup'

import { i18n } from '@/common/i18n'

import { isSequential } from './helpers'

export const usernameLoginValidationSchema = yup.object().shape({
  username: yup
    .string()
    .min(4, i18n().common.validators.min(4))
    .max(100, i18n().common.validators.max(100))
    .required(i18n().common.validators.required),
  password: yup
    .string()
    .min(6, i18n().common.validators.min(6))
    .max(100, i18n().common.validators.max(100))
    .required(i18n().common.validators.required),
  organizationId: yup.string().when('username', (username: string[], schema) => {
    const name = username[0]
    const isNotEmail = name ? !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(name) : true
    return isNotEmail
      ? schema.required(i18n().common.validators.required) // Torna obrigatório
      : schema.notRequired() // Não obrigatório
  })
})

export const organizationLoginValidationSchema = yup.object().shape({
  organizationId: yup.string().required(i18n().common.validators.required)
})

export const recoverPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n().common.validators.emailInvalid)
    .required(i18n().common.validators.required)
})

export const changePasswordValidationSchema = yup.object().shape({
  code: yup
    .string()
    .min(6, i18n().common.validators.codeInvalid)
    .required(i18n().common.validators.required),
  newPassword: yup
    .string()
    .min(8, i18n().common.validators.minPasswordInvalid)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, i18n().common.validators.passwordComplexity)
    .matches(/[!@#$%&.]/, i18n().common.validators.passwordNotSpecialCharacter)
    .matches(/\d/, i18n().common.validators.passwordNotNumber)
    .required(i18n().common.validators.required)
    .test({
      name: 'noSequentialCharacters',
      message: i18n().common.validators.passwordNotSequentialCharacters,
      test: (value) => {
        return !isSequential(value)
      }
    }),
  confirmNewPassword: yup
    .string()
    .required(i18n().common.validators.required)
    .oneOf([yup.ref('newPassword')], i18n().common.validators.notEqualsPasswordInvalid)
})

export const resetPasswordValidationSchema = (
  isSchool: boolean,
  username: string,
  tempPassword: string
): yup.ObjectSchema<{ newPassword: string; confirmNewPassword: string }> => {
  if (isSchool) {
    return yup.object().shape({
      newPassword: yup
        .string()
        .min(6, i18n().common.validators.minPasswordInvalid)
        .required(i18n().common.validators.required)
        .test({
          name: 'notEqualToUsername',
          message: i18n().common.validators.passwordEqualToUsername,
          test: (value) => {
            return value.toLowerCase() !== username.toLocaleLowerCase()
          }
        })
        .test({
          name: 'notEqualToTempPassword',
          message: i18n().common.validators.passwordEqualToTempPassword,
          test: (value) => {
            return value !== tempPassword
          }
        })
        .test({
          name: 'noSequentialCharacters',
          message: i18n().common.validators.passwordNotSequentialCharacters,
          test: (value) => {
            return !isSequential(value)
          }
        }),
      confirmNewPassword: yup
        .string()
        .required(i18n().common.validators.required)
        .oneOf([yup.ref('newPassword')], i18n().common.validators.notEqualsPasswordInvalid)
    })
  } else {
    return yup.object().shape({
      newPassword: yup
        .string()
        .min(6, i18n().common.validators.minPasswordInvalid)
        .required(i18n().common.validators.required)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, i18n().common.validators.passwordComplexity)
        .matches(/[!@#$%&.]/, i18n().common.validators.passwordNotSpecialCharacter)
        .matches(/\d/, i18n().common.validators.passwordNotNumber)
        .test({
          name: 'noSequentialCharacters',
          message: i18n().common.validators.passwordNotSequentialCharacters,
          test: (value) => {
            return !isSequential(value)
          }
        })
        .test({
          name: 'notEqualToUsername',
          message: i18n().common.validators.passwordEqualToUsername,
          test: (value) => {
            return value.toLocaleLowerCase() !== username.toLocaleLowerCase()
          }
        })
        .test({
          name: 'notEqualToTempPassword',
          message: i18n().common.validators.passwordEqualToTempPassword,
          test: (value) => {
            return value !== tempPassword
          }
        }),
      confirmNewPassword: yup
        .string()
        .required(i18n().common.validators.required)
        .oneOf([yup.ref('newPassword')], i18n().common.validators.notEqualsPasswordInvalid)
    })
  }
}

export const quickAccessValidationSchema = yup.object().shape({
  username: yup.string().required(i18n().common.validators.required),
  code: yup
    .array()
    .of(yup.string().required(i18n().common.validators.required))
    .required(i18n().common.validators.required)
})

export const loginSupportValidationSchema = yup.object().shape({
  organizationId: yup
    .number()
    .typeError(i18n().common.validators.required)
    .required(i18n().common.validators.required),
  userCode: yup.string().required(i18n().common.validators.required)
})
