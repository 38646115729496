import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Checkbox } from '@positivote/design-system/components/Checkbox'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { LI } from '@positivote/design-system/components/LI'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Select } from '@positivote/design-system/components/Select'
import { Span } from '@positivote/design-system/components/Span'
import { Switch } from '@positivote/design-system/components/Switch'
import { TextField, TextFieldRef } from '@positivote/design-system/components/TextField'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useTheme } from '@positivote/design-system/hooks'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { CloseIcon } from '@positivote/design-system/icons/Close'
import { DeleteIcon } from '@positivote/design-system/icons/Delete'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { TextDialog } from '@/common/components/TextDialog'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { ManageSearchIcon } from '@/fixme/icons/ManageSearch'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ListProfileHookParams } from '@/modules/hub/profiles/contracts'
import { useListProfileRoles, useUpdateProfileConsent } from '@/modules/hub/profiles/hooks'
import { useBulkRemoveUser, useListUser } from '@/modules/hub/users/hooks'

export function MyUsersList(): JSX.Element {
  changePageTitle(i18n().modules.hub.users.pages.list.pageTitle)

  const { profile } = useAuth()
  const bulkRemoveUser = useBulkRemoveUser()
  const searchTextFieldRef = useRef<TextFieldRef>(null)
  const [showFilter, setShowFilter] = useState(false)
  const [loadingConsent, setLoadingConsent] = useState<Record<string, boolean>>({})
  const [usersIdsToRemove, setUsersIdsToRemove] = useState<number[]>([])
  const [usersIdToRemove, setUsersIdToRemove] = useState<number[]>([])
  const [removeUserDialog, setRemoveUserDialog] = useState<{
    title: string
    id: number
    isBulk: boolean
  } | null>(null)
  const [listProfileParams, setListProfileParams] = useState<ListProfileHookParams['model']>({
    page: 1,
    perPage: 25,
    orgId: profile?.organizationId
  })

  const navigate = useNavigate()
  const updateProfileConsent = useUpdateProfileConsent()
  const listProfileRoles = useListProfileRoles({})
  const { breakpoint } = useTheme()
  const listProfile = useListUser({ model: listProfileParams })

  const bonds = [
    {
      label: i18n().modules.hub.users.pages.list.filter.bonds.studentsWithClasses,
      value: 'students_with_classes'
    },
    {
      label: i18n().modules.hub.users.pages.list.filter.bonds.studentsWithoutClasses,
      value: 'students_without_classes'
    },
    {
      label: i18n().modules.hub.users.pages.list.filter.bonds.teachersWithClasses,
      value: 'teachers_with_classes'
    },
    {
      label: i18n().modules.hub.users.pages.list.filter.bonds.teachersWithoutClasses,
      value: 'teachers_without_classes'
    },
    {
      label: i18n().modules.hub.users.pages.list.filter.bonds.guardiansWithStudents,
      value: 'guardians_with_students'
    },
    {
      label: i18n().modules.hub.users.pages.list.filter.bonds.guardiansWithoutStudents,
      value: 'guardians_without_students'
    }
  ]

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setListProfileParams((oldState) => ({ ...oldState, search, page: 1 }))
    })()
  }

  function clearSearchText(): void {
    setListProfileParams((oldState) => ({ ...oldState, search: undefined, page: 1 }))
    searchTextFieldRef.current?.clearInput()
  }

  function handleImageConsent(profileId: number, consent: boolean): void {
    setLoadingConsent((oldState) => ({
      ...oldState,
      [profileId]: true
    }))
    updateProfileConsent.mutate({
      model: {
        ...listProfileParams,
        profileId,
        consent: !consent
      },
      onSuccess: () => {
        setLoadingConsent((oldState) => ({
          ...oldState,
          [profileId]: false
        }))
      },
      onError: () => {
        setLoadingConsent((oldState) => ({
          ...oldState,
          [profileId]: false
        }))
      }
    })
  }

  const isAllUsersSelected = useMemo(() => {
    const usersIds = listProfile.data?.registers.map((currentProfile) => currentProfile.id) ?? []
    return usersIds.every((id) => usersIdsToRemove.includes(id))
  }, [listProfile.data?.registers, usersIdsToRemove])

  function handleSelectAllUsers(): void {
    const usersIds = listProfile.data?.registers.map((user) => user.id) ?? []

    const allSelected = usersIds.every((id) => usersIdsToRemove.includes(id))

    if (allSelected) {
      setUsersIdsToRemove((prevSelected) => prevSelected.filter((id) => !usersIds.includes(id)))
    } else {
      setUsersIdsToRemove((prevSelected) => [...prevSelected, ...usersIds])
    }
  }

  function handleSelectUsers(id: number): void {
    setUsersIdsToRemove((old) => {
      if (old.includes(id)) {
        return old.filter((userId) => userId !== id)
      } else {
        return [...old, id]
      }
    })
  }

  function handleRemoveUser(): void {
    const filteredUsersIdsToRemove = usersIdsToRemove.filter((userId) => userId !== profile!.id)
    bulkRemoveUser.mutate({
      model: {
        ids: usersIdToRemove.length ? usersIdToRemove : filteredUsersIdsToRemove
      },
      institutionId: profile!.organizationId,
      page: listProfileParams.page!,
      perPage: listProfileParams.perPage!,
      onSuccess: () => {
        if (listProfile.data?.registers.length === 1 || isAllUsersSelected) {
          setShowFilter(false)
          setListProfileParams((oldData) => ({
            ...oldData,
            search: undefined,
            page: (oldData.page ?? 1) - 1 || 1
          }))
        }
        setRemoveUserDialog(null)
      }
    })
  }

  useEffect(() => {
    if (listProfile.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listProfile.data?.registers.length, showFilter])

  const breadcrumbItems = [
    {
      label: i18n().modules.hub.users.pages.list.appBar.breadcrumbs.overview,
      onClick: () => navigate(-1)
    },
    {
      label: i18n().modules.hub.users.pages.list.appBar.breadcrumbs.title
    }
  ]

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <TextDialog
        isOpen={!!removeUserDialog?.id || !!removeUserDialog?.isBulk}
        title={{
          label: i18n().modules.hub.users.pages.list.removeDialog.title(
            removeUserDialog?.isBulk ?? false
          )
        }}
        contentTexts={
          removeUserDialog?.isBulk
            ? [
                <>
                  <Div>
                    <Typography
                      data-testid="Typography-removeDialog-contentText"
                      variant="bodyLarge"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.hub.users.pages.list.removeDialog.bulkContentTextMain}
                      <Span css={{ fontWeight: 'bold' }}>
                        {i18n().modules.hub.users.pages.list.removeDialog.selections}
                      </Span>
                    </Typography>
                  </Div>
                </>
              ]
            : [
                <>
                  <Div>
                    <Typography
                      data-testid="Typography-removeDialog-contentText"
                      variant="bodyLarge"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.hub.users.pages.list.removeDialog.contentTextMain}
                      <Span css={{ fontWeight: 'bold' }}>
                        {i18n().modules.hub.users.pages.list.removeDialog.selectedName(
                          removeUserDialog?.title
                        )}
                      </Span>
                    </Typography>
                  </Div>
                </>
              ]
        }
        onCancel={() => setRemoveUserDialog(null)}
        refuseAction={{
          icon: <CancelIcon size={18} />,
          label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.refuse,
          handle: () => setRemoveUserDialog(null)
        }}
        acceptAction={{
          icon: <DeleteIcon size={18} />,
          label: i18n().modules.hub.disciplines.pages.list.removeDialog.accept,
          handle: () => handleRemoveUser()
        }}
        isLoading={removeUserDialog?.isBulk ? bulkRemoveUser.isPending : bulkRemoveUser.isPending}
        css={{
          maxWidth: 600,
          '& .Dialog-Content': {
            gap: '$none'
          }
        }}
      />
      <AppBar
        title={i18n().modules.hub.users.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={breadcrumbItems}
        trailingElement={
          <Button
            onClick={() => navigate('/data-management/my-users/form')}
            variant="tonal"
            LeadingIcon={<AddCircleIcon size={18} />}
          >
            {i18n().modules.hub.users.pages.list.appBar.newUser}
          </Button>
        }
      />
      <Div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && (
          <>
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
              <FilterListIcon size={18} />
              <Typography
                variant="titleMedium"
                data-testid="Typography-titleFilter"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.users.pages.list.filter.title}
              </Typography>
            </Div>
            <Div css={{ display: 'flex', gap: '$lg', marginBottom: '$lg' }}>
              <Grid xl={3}>
                <Select
                  label={i18n().modules.hub.users.pages.list.filter.select}
                  hasNoneOption
                  data-testid="roleCode"
                  optionKeyField="code"
                  optionTitleField="name"
                  options={listProfileRoles.data?.registers ?? []}
                  onChange={(role) =>
                    setListProfileParams((oldState) => ({
                      ...oldState,
                      roleCode: role?.id,
                      page: 1
                    }))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid xl={5}>
                <Select
                  label={i18n().modules.hub.users.pages.list.filter.selectUserLinks}
                  hasNoneOption
                  data-testid="bonds"
                  optionKeyField="value"
                  optionTitleField="label"
                  options={bonds}
                  onChange={(role) =>
                    setListProfileParams((oldState) => ({
                      ...oldState,
                      bonds: role?.value,
                      page: 1
                    }))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid xl={4}>
                <TextField
                  ref={searchTextFieldRef}
                  label={i18n().modules.hub.users.pages.list.filter.searchLabel}
                  variant="outlined"
                  data-testid="search"
                  inputProps={{ onChange: handleChangeSearchText }}
                  css={{ marginBottom: '$md' }}
                  leadingIcon={{ icon: SearchIcon }}
                  trailingIcon={
                    listProfileParams.search
                      ? { icon: CloseIcon, onClick: clearSearchText }
                      : undefined
                  }
                />
              </Grid>
            </Div>
            {listProfile.isFetching && listProfileParams.search && (
              <Typography
                variant="titleLarge"
                css={{ color: '$on-surface', marginBottom: '$lg' }}
                data-testid="Typography-searching"
              >
                {i18n().modules.hub.users.pages.list.filter.searching}
              </Typography>
            )}
            {!listProfile.isFetching &&
              !!listProfile.data?.registers.length &&
              listProfileParams.search && (
                <Typography
                  variant="titleLarge"
                  css={{ color: '$on-surface', marginBottom: '$lg' }}
                  data-testid="Typography-searchingResult"
                >
                  {i18n().modules.hub.users.pages.list.filter.searchingResult}
                </Typography>
              )}
          </>
        )}

        {!listProfile.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            {listProfile.isFetching && (
              <Loader data-testid="Loader-Container-MyUsersList" size={80} />
            )}
            {!listProfile.isFetching && !listProfileParams.search && (
              <EmptyList title={i18n().modules.hub.users.pages.list.emptyList} />
            )}
            {!listProfile.isFetching && listProfileParams.search && <EmptySearch />}
          </Div>
        ) : (
          <>
            <Grid spacing="$md" css={{ padding: '$md $lg', marginBottom: '$2xs' }}>
              <Grid xl={4} lg={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                <Checkbox
                  data-testid="isAllUsersSelected"
                  inputProps={{ checked: isAllUsersSelected }}
                  onClick={handleSelectAllUsers}
                />
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerUsername"
                >
                  {i18n().modules.hub.users.pages.list.header.username}
                </Typography>
              </Grid>
              <Grid xl={2} css={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerEnrollment"
                >
                  {i18n().modules.hub.users.pages.list.header.enrollment}
                </Typography>
              </Grid>

              <Grid xl={2} lg={3} css={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip
                  placement="bottom-start"
                  label={i18n().modules.hub.users.pages.list.header.imageUsageTerm}
                >
                  <Typography
                    variant="titleMedium"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                    data-testid="Typography-headerImageUsageTerm"
                  >
                    {i18n().modules.hub.users.pages.list.header.imageUsageTerm}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid xl={2} css={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerProfile"
                >
                  {i18n().modules.hub.users.pages.list.header.profile}
                </Typography>
              </Grid>
              <Grid
                xl={2}
                css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              >
                <IconButton
                  disabled={!usersIdsToRemove.length}
                  onClick={() => setRemoveUserDialog((oldData) => ({ ...oldData!, isBulk: true }))}
                  data-testid="remove-all"
                  variant="standard"
                >
                  <DeleteIcon size={24} />
                </IconButton>
              </Grid>
            </Grid>
            <UL
              css={{
                ...(listProfile.isFetching && {
                  ...(listProfile.data.lastPage <= 1 && { flex: 1 }),
                  maxHeight: '50vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                })
              }}
            >
              {listProfile.isFetching && (
                <Loader data-testid="Loader-Container-MyUsersList" size={80} />
              )}
              {listProfile.data.registers.map((register, index) => (
                <BaseCard
                  key={register.id}
                  data-testid={`BaseCard-Container-contractListItem-${index}`}
                  component={LI}
                  css={{
                    opacity: listProfile.isFetching ? '$transparent' : '$default',
                    marginBottom: '$2xs',
                    '&:last-child': { marginBottom: '$none' },
                    '& .BaseCard-StateLayer': { height: 'min-content', padding: '$md $lg' }
                  }}
                >
                  <Grid spacing="$md" css={{ display: 'flex', alignItems: 'center' }}>
                    <Grid xl={4} lg={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                      <Checkbox
                        // disabled={profile?.id === register.id}
                        inputProps={{
                          checked: usersIdsToRemove.includes(register.id),
                          onClick: () => handleSelectUsers(register.id)
                        }}
                      />
                      <Image
                        alt={`${register.firstName} picture`}
                        src={register.picture}
                        FallbackImage={() => (
                          <IconWrapper
                            data-testid="UsersList-FallbackImage"
                            size="$2xl"
                            css={{ backgroundColor: '$primary-container' }}
                          >
                            <PersonIcon fill="$on-primary-container" />
                          </IconWrapper>
                        )}
                        css={{
                          height: '$2xl',
                          width: '$2xl',
                          borderRadius: '$full',
                          objectFit: 'cover'
                        }}
                      />
                      <Tooltip placement="bottom-start" label={register.firstName}>
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-listItemUsername-${index}`}
                        >
                          {`${register.firstName} ${register.lastName}`}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid xl={2} lg={2}>
                      <Typography
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                        data-testid={`Typography-listItemEnrollment-${index}`}
                      >
                        {register.code}
                      </Typography>
                    </Grid>

                    <Grid
                      xl={2}
                      lg={3}
                      css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}
                    >
                      <Switch
                        isLoading={!!loadingConsent[register.id]}
                        data-testid={`Switch-Container-${register.id}`}
                        inputProps={{
                          checked: register.allowedImageUseOnEdtech,
                          'data-testid': `Switch-Input-${register.id}`,
                          onChange: () =>
                            handleImageConsent(register.id, register.allowedImageUseOnEdtech)
                        }}
                        css={{ padding: '$2xs' }}
                      />
                      <Tooltip
                        placement="bottom-start"
                        label={i18n().modules.hub.users.pages.list.body.switchLabel(
                          register.allowedImageUseOnEdtech
                        )}
                      >
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-listItemImageUsageTerm-${index}`}
                        >
                          {i18n().modules.hub.users.pages.list.body.switchLabel(
                            register.allowedImageUseOnEdtech
                          )}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid xl={2} lg={2} css={{ display: 'flex', gap: '$sm' }}>
                      {register.roles.slice(0, breakpoint >= Breakpoint.xl ? 2 : 1).map((role) => (
                        <Chip
                          key={role.idRole}
                          label={role.name}
                          color="neutral"
                          data-testid={`listItemProfile-${index}`}
                        />
                      ))}
                      {register.roles.length > (breakpoint >= Breakpoint.xl ? 2 : 1) && (
                        <Chip
                          label={`+${register.roles.length - (breakpoint >= Breakpoint.xl ? 2 : 1)}`}
                          color="neutral"
                          data-testid="listItemProfile-more"
                        />
                      )}
                    </Grid>
                    <Grid xl={2} css={{ display: 'flex', justifyContent: 'flex-end', gap: '$md' }}>
                      <IconButton
                        onClick={() => navigate(`/data-management/my-users/${register.id}`)}
                        data-testid={`edit-${index}`}
                        variant="standard"
                      >
                        <ManageSearchIcon size={24} />
                      </IconButton>
                      <IconButton
                        onClick={() => navigate(`/data-management/my-users/form/${register.id}`)}
                        data-testid={`edit-${index}`}
                        variant="standard"
                      >
                        <EditIcon size={24} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setUsersIdToRemove([register.id])
                          setRemoveUserDialog({
                            id: register.id,
                            title: `${register.firstName} ${register.lastName}`,
                            isBulk: false
                          })
                        }}
                        data-testid={`remove-${index}`}
                        variant="standard"
                      >
                        <DeleteIcon size={24} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </BaseCard>
              ))}
            </UL>
            {listProfile.data.lastPage > 1 && (
              <Pagination
                lastPage={listProfile.data.lastPage}
                page={listProfileParams.page ?? 1}
                setPage={(page) => setListProfileParams((oldState) => ({ ...oldState, page }))}
                css={{ marginTop: '$lg' }}
              />
            )}
          </>
        )}
      </Div>
    </Main>
  )
}
