import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormAutocomplete } from '@positivote/design-system/components-form/Autocomplete'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { AccountBalanceIcon } from '@positivote/design-system/icons/AccountBalance'
import { KeyIcon } from '@positivote/design-system/icons/Key'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { VisibilityIcon } from '@positivote/design-system/icons/Visibility'
import { VisibilityOffIcon } from '@positivote/design-system/icons/VisibilityOff'
import { useEffect, useRef, useState } from 'react'
import { isIOS } from 'react-device-detect'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { MAX_PER_PAGE } from '@/common/constants/react-query'
import { changePageTitle, debounceEvent, emailRegex } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { INPUT_SCROLL_DELAY_IN_MS } from '@/modules/hub/auth/constants'
import { useAuth } from '@/modules/hub/auth/contexts'
import { PasswordLoginForm } from '@/modules/hub/auth/contracts/forms'
import { passwordLoginFormSanitizer } from '@/modules/hub/auth/sanitizers'
import { usernameLoginValidationSchema } from '@/modules/hub/auth/validations'
import { ShortOrganizationFormatted } from '@/modules/hub/organizations/contracts'
import { useListShortOrganization } from '@/modules/hub/organizations/hooks'

import './style.css' // Importa o CSS com a animação

interface PasswordFormLoginProps {
  scrollIntoView?: () => void
}

export function PasswordFormLogin({ scrollIntoView }: PasswordFormLoginProps): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.login.pageTitle)

  const navigate = useNavigate()
  const {
    createSession,
    isLoading,
    isLoadingGoogle,
    isLoadingMicrosoft,
    whiteLabel,
    showWhiteLabel
  } = useAuth()
  const { handleError } = useErrorHandler({ ignoreLogout: true })
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [newUserName, setNewUserName] = useState('')
  const [selectedOrganization, setSelectedOrganization] =
    useState<ShortOrganizationFormatted | null>(null)

  const [lastSchools, setLastSchools] = useState<ShortOrganizationFormatted[] | null>(null)
  const [isEmail, setIsEmail] = useState(false)
  const location = useLocation()
  const locationState = location.state as {
    userName: string
    password: string
    orgId: string
  } | null

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    clearErrors
  } = useForm<PasswordLoginForm>({
    mode: 'onSubmit',
    defaultValues: {
      username: ''
    },
    resolver: async (values, ...args) =>
      yupResolver(usernameLoginValidationSchema)(passwordLoginFormSanitizer(values), ...args)
  })

  function clearCreateSessionError(): void {
    clearErrors('organizationId')
    if (errorMessage) {
      setErrorMessage('')
    }
  }

  const formUsername = watch('username')
  const formPassword = watch('password')
  const formOrganizationId = watch('organizationId')

  const listShortOrganizationEnabled = !!formUsername && !!formPassword && !!searchText
  const { data, isFetching: organizationIsFetching } = useListShortOrganization({
    model: { perPage: MAX_PER_PAGE, callPublic: true, search: searchText || undefined },
    queryOptions: {
      enabled: listShortOrganizationEnabled
    }
  })

  const hasAnyError = !!Object.keys(errors).length
  const organizationIsLoadingParsed = organizationIsFetching && listShortOrganizationEnabled

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    debounceEvent(() => {
      setSearchText(value)
    })()
  }

  useEffect(() => {
    if (whiteLabel) {
      setValue('organizationId', String(locationState?.orgId))
    }
    // DOCS: only render whiteLabel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whiteLabel])

  useEffect(() => {
    if (locationState) {
      setValue('password', locationState.password)
      setValue('username', locationState.userName)
    }
    // DOCS: only render locationState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState])

  function onSubmit(model: PasswordLoginForm): void {
    const orgId = whiteLabel?.org.id ?? Number(formOrganizationId)
    if (isEmail || orgId) {
      void createSession({
        model:
          orgId && !isEmail
            ? {
                authFlow: 'ESCOLA',
                username: model.username,
                password: model.password,
                orgId
              }
            : {
                authFlow: 'EMAIL',
                username: model.username,
                password: model.password
              },
        onError: ({ error }) => {
          if (error.code) {
            if (['LOGIN.USER_NOT_FOUND', 'LOGIN.PASSWORD_ERROR'].includes(error.code)) {
              setErrorMessage(i18n().modules.hub.auth.pages.login.errors.wrongUserOrPassword)
            } else if (error.code === 'LOGIN.PROFILE_NOT_FOUND') {
              setErrorMessage(i18n().modules.hub.auth.pages.login.errors.profileNotFound)
            } else {
              handleError({ error })
            }
          }
        },
        onSuccess: () => {
          if (selectedOrganization?.wlActive) {
            const currentLatestSchools = localStorage.getItem('latestSchools')
            const selectedOrganizationId = selectedOrganization.id
            if (currentLatestSchools) {
              const updateLatestSchools = JSON.parse(
                currentLatestSchools
              ) as ShortOrganizationFormatted[]

              const alreadyExists = updateLatestSchools.some(
                (org) => org.id === selectedOrganizationId
              )

              if (!alreadyExists) {
                const lastTwo = 2
                localStorage.setItem(
                  'latestSchools',
                  JSON.stringify([selectedOrganization, ...updateLatestSchools].slice(0, lastTwo))
                )
              }
            } else {
              localStorage.setItem('latestSchools', JSON.stringify([selectedOrganization]))
            }
          }
        }
      })
    } else {
      navigate('/organizations', { state: model })
    }
  }

  function handleRemoveSchool(schoolId: number): void {
    const currentLatestSchools = localStorage.getItem('latestSchools')

    if (currentLatestSchools) {
      const latestSchools = JSON.parse(currentLatestSchools) as ShortOrganizationFormatted[]

      const updatedLatestSchools = latestSchools.filter((school) => school.id !== schoolId)

      localStorage.setItem('latestSchools', JSON.stringify(updatedLatestSchools))
      setLastSchools(updatedLatestSchools)
    }
  }

  useEffect(() => {
    const schools = localStorage.getItem('latestSchools')
    if (schools !== null) {
      setLastSchools(JSON.parse(schools) as ShortOrganizationFormatted[])
    }
  }, [])

  useEffect(() => {
    if (formUsername && !formUsername.length) {
      setNewUserName('')
    }
  }, [watch('username')])

  const [isOverllaping, setIsOverllaping] = useState(false)

  function checkAutofillValue(): void {
    const inputs = document.querySelectorAll<HTMLInputElement>(
      'input[name="username"], input[name="password"]'
    )

    const updateValues = (): void => {
      inputs.forEach((input) => {
        if (input.value === '') {
          setIsOverllaping(true)
        } else {
          setIsOverllaping(false)
        }
        if (input.name === 'username' && input.value) {
          setIsEmail(emailRegex.test(input.value))
          setNewUserName(input.value)
        }
      })
    }

    const handleAutofill = (): void => {
      setTimeout(updateValues, 0)
    }

    inputs.forEach((input) => {
      input.addEventListener('animationstart', handleAutofill)
    })
  }

  useEffect(() => {
    checkAutofillValue()
  }, [])

  return (
    <FormContainer
      formHandleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading || isLoadingGoogle || isLoadingMicrosoft}
      css={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      <Div css={{ display: 'flex' }}>
        <IconWrapper
          size="$2xl"
          css={{
            backgroundColor: '$primary-container',
            marginTop: '$2xs',
            marginRight: '$md',
            '@xs': { display: 'none' }
          }}
        >
          <PersonIcon fill="$on-primary-container" />
        </IconWrapper>
        <FormTextField
          shrunken={isOverllaping}
          control={control}
          name="username"
          label={i18n().modules.hub.auth.pages.login.inputs.username}
          variant="outlined"
          hasError={!!errorMessage}
          errorText={errors.username?.message}
          inputProps={{
            // TODO: put these props by default in text fields input
            autoComplete: 'new-password',
            autoCapitalize: 'off',
            autoCorrect: 'off',
            spellCheck: false,
            inputMode: 'email',
            autoFocus: true,
            onBlur: (e) => {
              setIsEmail(emailRegex.test(e.target.value))
              setNewUserName(e.target.value)
            },
            onChange: clearCreateSessionError,
            onFocus: scrollIntoView
          }}
          required
        />
      </Div>
      <Div css={{ display: 'flex', marginTop: '$lg' }}>
        <IconWrapper
          size="$2xl"
          css={{
            backgroundColor: '$primary-container',
            marginTop: '$2xs',
            marginRight: '$md',
            '@xs': { display: 'none' }
          }}
        >
          <KeyIcon fill="$on-primary-container" />
        </IconWrapper>
        <FormTextField
          shrunken={isOverllaping}
          control={control}
          name="password"
          label={i18n().modules.hub.auth.pages.login.inputs.password}
          variant="outlined"
          errorText={errors.password?.message ?? errorMessage}
          css={
            // DOCS: its necessary due to ios issue
            // https://stackoverflow.com/questions/66151183/inputtype-password-ios-not-rendering-keyboard-safarichrome
            !isIOS
              ? undefined
              : showPassword
                ? undefined
                : {
                    '& .TextField-Input': {
                      WebkitTextSecurity: 'disc',
                      MozWebkitTextSecurity: 'disc',
                      MozTextSecurity: 'disc'
                    }
                  }
          }
          inputProps={{
            // DOCS: its necessary due to ios issue
            // https://stackoverflow.com/questions/66151183/inputtype-password-ios-not-rendering-keyboard-safarichrome
            type: isIOS ? undefined : showPassword ? 'text' : 'password',
            // TODO: put these props by default in text fields input
            autoComplete: 'new-password',
            autoCapitalize: 'off',
            autoCorrect: 'off',
            spellCheck: false,
            autoFocus: false,
            onChange: clearCreateSessionError,
            onFocus: scrollIntoView,
            onBlur: () => {
              setIsEmail(emailRegex.test(getValues('username')))
              setNewUserName(getValues('username'))
            }
          }}
          trailingIcon={{
            icon: showPassword ? VisibilityIcon : VisibilityOffIcon,
            onClick: () => setShowPassword((oldState) => !oldState),
            changeIconOnError: false
          }}
          required
        />
      </Div>
      {!isEmail && newUserName && !whiteLabel && (
        <Div css={{ display: 'flex', marginTop: '$lg' }}>
          <IconWrapper
            size="$2xl"
            css={{
              backgroundColor: '$primary-container',
              marginTop: '$2xs',
              marginRight: '$md',
              '@xs': { display: 'none' }
            }}
          >
            <AccountBalanceIcon fill="$on-primary-container" />
          </IconWrapper>
          <FormAutocomplete
            control={control}
            name="organizationId"
            label={i18n().modules.hub.auth.pages.organizationList.searchInput.label}
            options={data?.registers ?? []}
            optionKeyField="id"
            optionTitleField="name"
            optionSubTitleField="address"
            variant="outlined"
            leadingIcon={{ icon: SearchIcon }}
            onChange={(event) => setSelectedOrganization(event)}
            errorText={errors.organizationId?.message}
            isLoading={organizationIsLoadingParsed}
            inputProps={{
              // TODO: put these props by default in text fields input
              autoComplete: 'new-password',
              autoCapitalize: 'off',
              autoCorrect: 'off',
              spellCheck: false,
              autoFocus: false,
              onChange: handleChangeSearchText,
              onFocus: () =>
                debounceEvent(
                  () => wrapperRef.current?.scrollIntoView({ behavior: 'smooth' }),
                  INPUT_SCROLL_DELAY_IN_MS
                )()
            }}
          />
        </Div>
      )}
      {!whiteLabel && lastSchools && !!lastSchools.length && (
        <Div css={{ display: 'flex', marginTop: '$lg' }}>
          <IconWrapper
            size="$2xl"
            css={{
              backgroundColor: '$primary-container',
              marginTop: '$2xs',
              marginRight: '$md',
              opacity: '$transparent',
              '@xs': { display: 'none' }
            }}
          >
            <AccountBalanceIcon fill="$on-primary-container" />
          </IconWrapper>
          <Div css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}>
            <Typography variant="bodySmall" css={{ color: '$on-surface-variant' }}>
              {i18n().modules.hub.auth.pages.login.lastSelectedSchools}
            </Typography>
            <Div css={{ display: 'flex', gap: '$2xs' }}>
              {lastSchools.map((school) => (
                <Chip
                  color="neutral"
                  key={school.id}
                  label={school.name}
                  onClick={() => {
                    navigate(`/${school.dscUrlPage}`, {
                      state: { userName: formUsername, password: formPassword, orgId: school.id }
                    })
                    showWhiteLabel(school.dscUrlPage)
                  }}
                  css={{ cursor: 'pointer' }}
                  onRemove={() => handleRemoveSchool(school.id)}
                />
              ))}
            </Div>
          </Div>
        </Div>
      )}

      <Div css={{ display: 'flex', marginTop: '$lg', '@xs': { flexDirection: 'column-reverse' } }}>
        <IconWrapper size="$2xl" css={{ marginRight: '$md', '@xs': { display: 'none' } }} />
        <Button
          data-testid="forgotPassword"
          variant="outlined"
          css={{
            flex: 1,
            marginRight: '$md',
            '@xs': { marginRight: '$none', marginTop: '$2xs' }
          }}
          disabled={isLoading || isLoadingGoogle || isLoadingMicrosoft}
          onClick={() => navigate('/recover-password')}
        >
          {i18n().modules.hub.auth.pages.login.buttons.forgotPassword}
        </Button>
        <Button
          type="submit"
          variant="filled"
          css={{ flex: 1 }}
          disabled={hasAnyError || isLoadingGoogle || isLoadingMicrosoft}
          isLoading={isLoading}
          data-testid="submitButton"
        >
          {i18n().modules.hub.auth.pages.login.buttons.next}
        </Button>
      </Div>
    </FormContainer>
  )
}
