import { Div } from '@positivote/design-system/components/Div'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Select } from '@positivote/design-system/components/Select'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { Typography } from '@positivote/design-system/components/Typography'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { ErrorIcon } from '@positivote/design-system/icons/Error'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { FirstCard, FirstCardProps } from '@/modules/hub/academic-sync/components/FirstCard'
import { ListDetailsDiscipline } from '@/modules/hub/academic-sync/components/ListDetailsDiscipline'
import { ListDetailsEnrollmentStudent } from '@/modules/hub/academic-sync/components/ListDetailsEnrollmentStudent'
import { ListDetailsEnrollmentTeacher } from '@/modules/hub/academic-sync/components/ListDetailsEnrollmentTeacher'
import { ListDetailsGuardian } from '@/modules/hub/academic-sync/components/ListDetailsGuardian'
import { ListDetailsSchoolClass } from '@/modules/hub/academic-sync/components/ListDetailsSchoolClass'
import { ListDetailsStaff } from '@/modules/hub/academic-sync/components/ListDetailsStaff'
import { ListDetailsStudent } from '@/modules/hub/academic-sync/components/ListDetailsStudent'
import { ListDetailsTeacher } from '@/modules/hub/academic-sync/components/ListDetailsTeacher'
import { useShowDiscipline } from '@/modules/hub/academic-sync/hooks'
import { useAuth } from '@/modules/hub/auth/contexts'

export function DetailsAcademicSync(): JSX.Element {
  const { profile } = useAuth()
  const params = useParams()
  const navigate = useNavigate()
  const [activeTab] = useState(0)
  const [selectedFilterStatus, setSelectedFilterStatus] = useState('all')

  const showAcademicSync = useShowDiscipline({
    model: {
      importId: params.id!,
      institutionId: profile?.organizationId as unknown as number
    },
    queryOptions: {
      enabled: !!params.id && !!profile
    }
  })
  changePageTitle(
    i18n().modules.hub.academicSync.pages.details.pageTitle(
      showAcademicSync.data?.filename ?? '...'
    )
  )

  const filterStatus = [
    {
      key: 'all',
      label: i18n().modules.hub.academicSync.pages.details.filterStatus.allStatus
    },
    {
      key: 'SUCCESS',
      label: i18n().modules.hub.academicSync.pages.details.filterStatus.success,
      icon: <CheckCircleIcon fill="$success" />
    },

    {
      key: 'ERROR',
      label: i18n().modules.hub.academicSync.pages.details.filterStatus.error,
      icon: <ErrorIcon fill="$critical" />
    }
  ]

  const firstCardData: FirstCardProps = {
    data: {
      schoolYearTitle: showAcademicSync.data?.schoolYearTitle,
      fileName: showAcademicSync.data?.filename,
      processed: showAcademicSync.data?.endOfProcessingFormatted,
      statusFile: showAcademicSync.data?.statusFormatted,
      submittedBy: showAcademicSync.data?.userFullname,
      time: showAcademicSync.data?.timeFormatted,
      totalLinks: showAcademicSync.data?.totalEnrollments,
      totalUsers: showAcademicSync.data?.totalUsers
    }
  }

  const options = [
    {
      label: `${i18n().modules.hub.academicSync.pages.details.students} (${showAcademicSync.data?.student.success}/${showAcademicSync.data?.student.total})`,
      key: 'student',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsStudent
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.student}
          />
        </Div>
      )
    },
    {
      label: `${i18n().modules.hub.academicSync.pages.details.disciplines} (${showAcademicSync.data?.course.success}/${showAcademicSync.data?.course.total})`,
      key: 'course',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsDiscipline
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.course}
          />
        </Div>
      )
    },
    {
      label: `${i18n().modules.hub.academicSync.pages.details.classes} (${showAcademicSync.data?.classes.success}/${showAcademicSync.data?.classes.total})`,
      key: 'classes',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsSchoolClass
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.classes}
          />
        </Div>
      )
    },
    {
      label: `${i18n().modules.hub.academicSync.pages.details.responsible} (${showAcademicSync.data?.guardian.success}/${showAcademicSync.data?.guardian.total})`,
      key: 'guardian',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsGuardian
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.guardian}
          />
        </Div>
      )
    },
    {
      label: `${i18n().modules.hub.academicSync.pages.details.teachers} (${showAcademicSync.data?.teacher.success}/${showAcademicSync.data?.teacher.total})`,
      key: 'teacher',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsTeacher
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.teacher}
          />
        </Div>
      )
    },
    {
      label: `${i18n().modules.hub.academicSync.pages.details.teacherLinks} (${showAcademicSync.data?.enrollmentTeacher.success}/${showAcademicSync.data?.enrollmentTeacher.total})`,
      key: 'enrollmentTeacher',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsEnrollmentTeacher
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.enrollmentTeacher}
          />
        </Div>
      )
    },
    {
      label: `${i18n().modules.hub.academicSync.pages.details.collaborators} (${showAcademicSync.data?.staff.success}/${showAcademicSync.data?.staff.total})`,
      key: 'staff',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsStaff
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.staff}
          />
        </Div>
      )
    },
    {
      label: `${i18n().modules.hub.academicSync.pages.details.studentsLinks} (${showAcademicSync.data?.enrollmentStudent.success}/${showAcademicSync.data?.enrollmentStudent.total})`,
      key: 'enrollmentStudent',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsEnrollmentStudent
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.enrollmentStudent}
          />
        </Div>
      )
    }
  ]

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.academicSync.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.academicSync.pages.details.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label: i18n().modules.hub.academicSync.pages.details.appBar.breadcrumbs.myImports,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: showAcademicSync.data?.filename
              ? `${i18n().modules.hub.academicSync.pages.details.appBar.breadcrumbs.file} - ${showAcademicSync.data.filename}`
              : '...'
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {!showAcademicSync.data ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              padding: '$lg',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {showAcademicSync.isFetching && (
              <Loader data-testid="Loader-Container-DisciplineList" size={80} />
            )}
          </Div>
        ) : (
          <Div css={{ display: 'flex', gap: '$lg', flexDirection: 'column' }}>
            <FirstCard data={firstCardData.data} />
            <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md' }}>
              <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                <FilterListIcon size={18} />
                <Typography
                  data-testid="Typography-titleFilter"
                  variant="titleMedium"
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.hub.academicSync.pages.details.filteringOptions}
                </Typography>
              </Div>
              <Select
                label={i18n().modules.hub.academicSync.pages.details.filterByStatus}
                variant="outlined"
                optionKeyField="key"
                leadingElementField="icon"
                optionTitleField="label"
                options={filterStatus}
                value={selectedFilterStatus}
                onChange={(event) => {
                  setSelectedFilterStatus(event!.key)
                }}
                css={{ width: '400px' }}
              />
            </Div>
            <Div css={{ display: 'flex', overflowX: 'scroll' }}>
              <Tabs options={options} currentTab={activeTab} />
            </Div>
          </Div>
        )}
      </Div>
    </Main>
  )
}
